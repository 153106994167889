<template>
  <el-row>
    <el-col :span="18">
      <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="flex justifyend">
              <el-button type="primary" @click="opendialog()">自定义物品入库</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table
        @selection-change="handleChangedrugdata"
        highlight-current-row
        :cell-style="{padding:'0px','text-align':'center'}"
        :data="items"
        class="drugdata"
        style="width: 100%; margin-bottom: 20px;font-size: 12px;"
        :header-cell-style="{background:'#f5f5f5','text-align':'center'}"
        row-key="_id"
        border
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="type" label="类型" fixed="left">
          <template slot-scope="scope">
            {{ getLabel(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="fullName" show-overflow-tooltip label="名称" width="140" fixed="left"></el-table-column>
        <el-table-column prop="manufacturer" show-overflow-tooltip label="生成厂商" width="140"></el-table-column>
        <el-table-column prop="displayspec" show-overflow-tooltip label="规格" width="120"></el-table-column>
        <el-table-column prop="procurenum" label="采购数量" width="160">
          <template slot-scope="scope">
            <el-input-number size="mini" v-model.number="scope.row.procurenum"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="packageprice" label="采购单价" width="160">
          <template slot-scope="scope">
            <el-input-number size="mini" v-model="scope.row.packageprice" :precision="2" :step="0.1"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="sellprice" label="销售单价" width="160">
          <template slot-scope="scope">
            <el-input-number size="mini" v-model="scope.row.sellprice" :precision="2" :step="0.1"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="GenerationDate" label="生产日期" :formatter="formatTime" width="100"></el-table-column>
        <el-table-column prop="ExpirationDate" label="过期日期" :formatter="formatTime" width="100"></el-table-column>
        <el-table-column prop="Creator" label="制单" width="110"></el-table-column>
        <el-table-column prop="createdAt" label="采购日期" width="110" :formatter="formatTime"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button style="padding: 5px 5px;margin:5px 0" type="danger" @click="deleteWarehousingdrugdata(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex aligncenter justifybetween">
        <div>
          <el-pagination
            background
            @current-change="handlePageChange"
            @size-change="handlePageSizeChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="flex aligncenter">
          <div class="mr30">入库：<span class="colorff2222">{{Changedrugdata.length}}</span> 条</div>
          <div class="mr30">总金额：<span class="colorff2222">{{ changedrugdatatotalPrice }}</span></div>
          <div><el-button type="success" size="small" @click="operateWarehousing">入库</el-button></div>
        </div>
      </div>
    </el-card>
    </el-col>
    <el-col :span="6" style="padding-left: 10px">
      <el-card class="box-card searchdrugdata">
        <div slot="header" class="clearfix">
          <div class="demo-input-suffix">
            <el-input placeholder="请输入需要查询的名称" v-model="searchdrugdatatext" @input="searchdrugdataInput" @blur="searchdrugdatatextblur"></el-input>
          </div>
        </div>
        <div>
          <el-tabs type="card" v-model="typename"  @tab-click="handleClicktab">
            <el-tab-pane label="中西成药" name="zhongxichengyao">
              <el-empty class="datalistbax" description="没有信息" v-if="zhongxichengyao.length === 0"></el-empty>
              <div class="pd10 datalistbax" v-if="zhongxichengyao.length > 0">
                <div class="ishover flex_row_space-between_center pd5" v-for="(item,index) in zhongxichengyao" :key="index">
                 <div class="w50">
                  <div class="text-overflow">{{ item.fullName }}</div>
                  <div class="text-overflow"><span class="colorgrad">{{ item.manufacturer }}</span></div>
                 </div>
                 <div class="flex alignright"><span class="text-overflow w105">{{ item.displayspec }}</span><el-link type="primary" :underline="false" class="ml30 coloradd" @click="opendrugdatadialog(item)">添加</el-link></div> 
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="中药" name="zhongyao">
              <el-empty class="datalistbax" description="没有信息" v-if="zhongyao.length === 0"></el-empty>
              <div class="pd10 datalistbax" v-if="zhongyao.length > 0">
                <div class="ishover flex_row_space-between_center pd5" v-for="(item,index) in zhongyao" :key="index">
                 <div class="w50">
                  <div class="text-overflow">{{ item.fullName }}</div>
                  <div class="text-overflow"><span class="colorgrad">{{ item.manufacturer }}</span></div>
                 </div>
                 <div class="flex alignright"><span class="text-overflow w105">{{ item.displayspec }}</span><el-link type="primary" :underline="false" class="ml30 coloradd" @click="opendrugdatadialog(item)">添加</el-link></div> 
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="pagination">
          <el-pagination
            @current-change="drugdatahandlePageChange"
            @size-change="drugdatahandlePageSizeChange"
            :current-page="drugdatacurrentPage"
            :page-size="drugdatapageSize"
            background
            small
            layout="prev, pager, next"
            :total="drugdatatotal">
          </el-pagination>
        </div>
        <el-dialog title="入库信息" :visible.sync="drugdataFormVisible" :close-on-click-modal="false">
          <el-form :model="drugdataForm" :rules="drugdatarules" ref="drugdataForm" label-width="100px" class="goodsForm">
            <el-form-item label="商品类型" prop="type">
                <el-select v-model="drugdataForm.type" :clearable="false" placeholder="商品类型">
                    <el-option
                        v-for="item in goodstypeoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="能否拆分" prop="dismounting">
                <el-select v-model="drugdataForm.dismounting" :clearable="false" placeholder="请选择">
                    <el-option
                        v-for="item in dismountingoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="商品名称" prop="fullName">
                <el-input v-model="drugdataForm.fullName"></el-input>
            </el-form-item>
            <el-form-item label="生产厂家" prop="manufacturer">
                <el-input v-model="drugdataForm.manufacturer"></el-input>
            </el-form-item>
            <el-form-item label="供应商" v-show="drugdataForm.type !== 4 && drugdataForm.type !== 5" prop="supplier">
                <el-select v-model="drugdataForm.supplier" filterable placeholder="请选择">
                  <el-option
                    v-for="item in supplieroptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="首级规格" v-show="drugdataForm.type !== 2 && drugdataForm.type !== 4 && drugdataForm.type !== 5" prop="medicineDosageNum">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">如商品规格为"100ml*30只/盒"<br/>则填入"100"没有就填0</div>
                    <el-input-number v-model="drugdataForm.medicineDosageNum" controls-position="right"></el-input-number>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="首级单位" v-show="drugdataForm.type !== 2 && drugdataForm.type !== 4 && drugdataForm.type !== 5" prop="medicinedosageunit">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">如商品规格为"100ml*30只/盒"<br/>则填入"ml"没有可不选</div>
                    <el-select v-model="drugdataForm.medicineDosageUnit" clearable filterable placeholder="请选择">
                        <el-option
                            v-for="item in units"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="次级规格" v-show="drugdataForm.type !== 2 && drugdataForm.type !== 4 && drugdataForm.type !== 5" prop="pieceNum">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">如商品规格为"100ml*30只/盒"<br/>则填入"30"没有就填0</div>
                    <el-input-number v-model="drugdataForm.pieceNum" controls-position="right"></el-input-number>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="次级单位" v-show="drugdataForm.type !== 2 && drugdataForm.type !== 4 && drugdataForm.type !== 5" prop="pieceunit">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">如商品规格为"100ml*30只/盒"<br/>则填入"只"没有可不选</div>
                    <el-select v-model="drugdataForm.pieceUnit" clearable filterable placeholder="请选择">
                        <el-option
                            v-for="item in units"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="整体规格" prop="displayspec">
                <el-input v-model="drugdataForm.displayspec"></el-input>
            </el-form-item>
            <el-form-item label="采购单位" prop="packageUnit">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">如商品规格为"100ml*30只/盒"<br/>则填入"盒"</div>
                    <el-select v-model="drugdataForm.packageUnit" :clearable="false" filterable placeholder="请选择">
                        <el-option
                            v-for="item in units"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="采购数量" prop="procurenum">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">输入该商品的采购数量<br/></div>
                    <el-input-number :precision="0" v-model.number="drugdataForm.procurenum" controls-position="right"></el-input-number>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="采购单价" prop="packageprice">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">输入该商品的采购单价<br/></div>
                    <el-input-number v-model="drugdataForm.packageprice" controls-position="right"></el-input-number>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="销售单价" prop="sellprice">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">输入该商品的销售单价<br/></div>
                    <el-input-number v-model="drugdataForm.sellprice" controls-position="right"></el-input-number>
                </el-tooltip>
            </el-form-item>
            <el-form-item v-if="drugdataForm.dismounting === 1" label="拆分单价" prop="pieceprice">
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">该单价为可拆分的单品价格如商品规格为"100ml*30只/盒，一盒30元<br/>那么单价为30/30只，1元</div>
                    <el-input-number :value="drugpieceprice" controls-position="right"></el-input-number>
                </el-tooltip>
            </el-form-item>
            <el-form-item label="生产日期" prop="GenerationDate" class="GenerationDate">
              <el-date-picker
                v-model="drugdataForm.GenerationDate"
                type="date"
                value-format="timestamp"
                :clearable="false"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="过期日期" prop="ExpirationDate" class="ExpirationDate">
              <el-date-picker
                v-model="drugdataForm.ExpirationDate"
                value-format="timestamp"
                :clearable="false"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="国药准字" v-show="drugdataForm.type === 2" prop="medicinenmpn">
                <el-input v-model="drugdataForm.medicinenmpn"></el-input>
            </el-form-item>
            <el-form-item label="用药方式" v-show="drugdataForm.type !== 2 && drugdataForm.type !== 4 && drugdataForm.type !== 5" prop="usage">
                <el-select v-model="drugdataForm.usage" clearable allow-create filterable placeholder="请选择">
                    <el-option
                        v-for="item in usageoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="用药频率" v-show="drugdataForm.type !== 2 && drugdataForm.type !== 4 && drugdataForm.type !== 5" prop="freq">
                <el-select v-model="drugdataForm.freq" clearable filterable placeholder="请选择">
                    <el-option
                        v-for="item in freqselect"
                        :key="item.name"
                        :label="item.frequency"
                        :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="默认数量" v-show="drugdataForm.type !== 2 && drugdataForm.type !== 4 && drugdataForm.type !== 5" prop="dosage">
                <el-input v-model.number="drugdataForm.dosage"></el-input>
            </el-form-item>
            <el-form-item label="默认单位" v-if="drugdataForm.medicineDosageUnit && drugdataForm.pieceUnit" prop="dosageUnit">
                <el-select v-model="drugdataForm.dosageUnit" clearable filterable placeholder="请选择">
                    <el-option
                        :label="drugdataForm.medicineDosageUnit"
                        :value="drugdataForm.medicineDosageUnit">
                    </el-option>
                    <el-option
                        :label="drugdataForm.pieceUnit"
                        :value="drugdataForm.pieceUnit">
                    </el-option>
                </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="clocedialog()">取 消</el-button>
            <el-button type="primary" v-if="!form._id" @click="Warehousingdrugdata(drugdataForm)">添 加</el-button>
          </div>
        </el-dialog>
      </el-card>
    </el-col>
  </el-row>
  </template>
  <script>
  import unitsData from '@/utils/unit';
  import excel from "@/utils/excel";
  import XLSX from "xlsx";
  export default {
    data() {
      return {
        dialogTableVisible: false,
        editdialogFormVisible: false,
        dialogFormVisible: false,
        drugdataFormVisible: false,
        drugdataForm:{},
        formLabelWidth: "120px",
        currentPage: 1, // 当前页码
        pageSize: 20, // 每页数据量
        total: 0, // 总数据量
        items: [],
        type:1,
        typename:"zhongxichengyao",
        drugdatacurrentPage:1,
        drugdatapageSize:20,
        drugdatatotal:0,
        zhongxichengyao: [],
        zhongyao: [],
        supplieroptions: [],
        Changedrugdata: [],
        timeslot:"",
        search: "",
        searchdrug: "",
        searchdate: "",
        units:unitsData,
        searchsupplier: "",
        searchdrugdatatext:"",
        form: {
            _id:"",
            goodstype:null,
            dismounting:0,
            name:"",
            manufacturer:"",
            medicinedosagenum:null,
            medicinedosageunit:null,
            piecenum:null,
            pieceunit:null,
            packageunit:null,
            packageprice:null,
            pieceprice:null,
            displayspec:"",
        },
        rules: {
            goodstype: [
                { required: true, message: '请选择商品类型', trigger: 'change' }
            ],
            dismounting: [
                { required: true, message: '请选择是否可以拆分', trigger: 'change' }
            ],
            name: [
                { required: true, message: '请输入商品名称', trigger: 'blur' },
            ],
            packageunit: [
                { required: true, message: '请选择单位，或者输入', trigger: 'change' }
            ],
            packageprice:[
                { type: 'number', required: true, message: '请输入价格', trigger: 'blur' }
            ]
        },
        drugdatarules: {
            type: [
                { required: true, message: '请选择商品类型', trigger: 'change' }
            ],
            dismounting: [
                { required: true, message: '请选择是否可以拆分', trigger: 'change' }
            ],
            fullName: [
                { required: true, message: '请输入商品名称', trigger: 'blur' },
            ],
            packageUnit: [
                { required: true, message: '请选择单位，或者输入', trigger: 'change' }
            ],
            packageprice:[
                { type: 'number', required: true, message: '请输入价格', trigger: 'blur' }
            ],
            procurenum:[
                { type: 'number', required: true, message: '请输入采购数量', trigger: 'blur' }
            ],
            sellprice:[
                { type: 'number', required: true, message: '请输入销售单价', trigger: 'blur' }
            ]
        },
        editform: {
          _id: "",
          name: "",
          rolename: "",
          des: "",
        },
        checkoptions: [],
        tableColumns: [], // 表格表头数据
        dismountingoptions: [{
            value: 0,
            label: '不能'
        },{
            value: 1,
            label: '能'
        }],
        goodstypeoptions: [{
          value: 1,
          label: '中西成药'
        }, {
          value: 2,
          label: '中药'
        }, {
          value: 3,
          label: '商品'
        }, {
          value: 6,
          label: '物资'
        }, {
          value: 7,
          label: '其它'
        }],
        options: [{
            value: '处方',
            label: '处方'
          }, {
            value: '非处方',
            label: '非处方'
        }],
        usageoptions:[
            { value: '口服', label: '口服' },
            { value: '含服', label: '含服' },
            { value: '嚼服', label: '嚼服' },
            { value: '晨服', label: '晨服' },
            { value: '餐前服', label: '餐前服' },
            { value: '餐中服', label: '餐中服' },
            { value: '餐后服', label: '餐后服' },
            { value: '睡前服', label: '睡前服' },
            { value: '静脉滴注', label: '静脉滴注' },
            { value: '静脉注射', label: '静脉注射' },
            { value: '肌内注射', label: '肌内注射' },
            { value: '腔内注射', label: '腔内注射' },
            { value: '雾化吸入', label: '雾化吸入' },
            { value: '皮下注射', label: '皮下注射' },
            { value: '皮内注射', label: '皮内注射' },
            { value: '穴位注射', label: '穴位注射' },
            { value: '直肠滴注', label: '直肠滴注' },
            { value: '局部注射', label: '局部注射' },
            { value: '局部麻醉', label: '局部麻醉' },
            { value: '超声透药', label: '超声透药' },
            { value: '溶媒用', label: '溶媒用' },
            { value: '外用', label: '外用' },
            { value: '滴眼', label: '滴眼' },
            { value: '滴鼻', label: '滴鼻' },
            { value: '滴耳', label: '滴耳' },
            { value: '口腔喷入', label: '口腔喷入' },
            { value: '鼻腔喷入', label: '鼻腔喷入' },
            { value: '含漱', label: '含漱' },
            { value: '涂抹', label: '涂抹' },
            { value: '塞肛', label: '塞肛' },
            { value: '直肠给药', label: '直肠给药' },
            { value: '阴道给药', label: '阴道给药' }
        ],
        freqselect:[
            { frequency: '每天1次', multiplier: 1, name: 'qd' },
            { frequency: '每天2次', multiplier: 2, name: 'bid' },
            { frequency: '每天3次', multiplier: 3, name: 'tid' },
            { frequency: '每天4次', multiplier: 4, name: 'qid' },
            { frequency: '隔日1次', multiplier: 0.5, name: 'qod' },
            { frequency: '每周1次', multiplier: 1 / 7, name: 'qw' },
            { frequency: '每周2次', multiplier: 2 / 7, name: 'biw' },
            { frequency: '每2小时1次', multiplier: 12, name: 'q2h' },
            { frequency: '每4小时1次', multiplier: 6, name: 'q4h' },
            { frequency: '每6小时1次', multiplier: 4, name: 'q6h' },
            { frequency: '每8小时1次', multiplier: 3, name: 'q8h' },
            { frequency: '每12小时1次', multiplier: 2, name: 'q12h' },
        ],
        //所有键的值
        columnHeader:[],
  
        // 导入的excel的数据
        excelData:[],
  
        // 表格显示列
        checkboxTableColumn:[],
  
        //表格数据
        tableData:[],
  
        // 当前分页
        upcurrentPage:1,
  
        // 每页显示数量
        uppageSize:10,
  
        // 数据总数
        uptotal:0
      };
    },
    computed: {},
    methods: {
      handleChangedrugdata(selectionArr) {
        let _targetArr = []
        // 遍历已选择数据id
        selectionArr.map((item) => {
            _targetArr.push(item)
        })
        this.Changedrugdata = _targetArr
        console.log(this.Changedrugdata)
      },
      debounce(func, delay) {
        clearTimeout(this.timer);
        this.timer = setTimeout(func, delay);
      },
      //防抖
      searchdrugdataInput() {
        this.debounce(this.searchallDrugdata, 1000);
      },
      searchdrugdatatextblur() {
        if(this.searchdrugdatatext === ''){
          this.drugdataItems(this.type)
        }
      },
      searchallDrugdata(type){
        const data = {
          currentPage: this.drugdatacurrentPage,
          pageSize: this.drugdatapageSize,
          type:type ? type : this.type,
          search:this.searchdrugdatatext
        };
        this.$api.drugdata.searchallDrugdata(data).then((res) => {
          this.drugdatacurrentPage = res.data.pagination.currentPage;
          this.drugdatapageSize = res.data.pagination.pageSize;
          this.drugdatatotal = res.data.pagination.total;
          console.log(this.drugdatatotal)
          if(this.type === 1){
            this.zhongxichengyao = res.data.data;
          }else{
            this.zhongyao = res.data.data;
          }
          
        });
      },
      getLabel(type) {
        const matchedOption = this.goodstypeoptions.find(option => option.value === type);
        return matchedOption ? matchedOption.label : '';
      },
      handleClicktab(tab, event) {
        console.log(tab.index);
        this.type = parseInt(tab.index) + 1
        this.drugdatacurrentPage = 1
        this.drugdataItems(this.type);
      },
      
      opendrugdatadialog(data){
        this.drugdataFormVisible = true
        this.drugdataForm = data;
      },
      opendialog(){
        this.drugdataFormVisible = true
        this.drugdataForm = {}
      },
      clocedialog(){
        this.dialogFormVisible = false;
        this.dialogTableVisible = false
        this.drugdataFormVisible = false
      },
      deleteWarehousingdrugdata(data){
        this.$api.goods.deleteWarehousingdrugdata(data).then((res) => {
          if(res.data.code === 200){
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.loadItems();
          }else{
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        })
      },
      Warehousingdrugdata(data) {
        this.$refs.drugdataForm.validate((valid) => {
            if (valid) {
              this.$api.goods.Warehousingdrugdata(data).then((res) => {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.drugdataFormVisible = false;
                this.loadItems();
              });
            } else {
              this.$message({
                  message: "请按规则填写",
                  type: "warning",
              });
              return false;
            }
        });
      },
      getallsupplier(){
        this.$api.inventory.allsupplier().then((res) =>{
          this.supplieroptions = res.data.data.map(item => {
            return {
              label:item.suppliername,
              value:item._id
            }
          })
        })
      },
      creategoods(data) {
        this.$refs.form.validate((valid) => {
            if (valid) {
              this.$api.goods.creategoods(data).then((res) => {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.loadItems();
              });
            } else {
              this.$message({
                  message: "请按规则填写",
                  type: "warning",
              });
              return false;
            }
        });
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.loadItems();
      },
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.loadItems();
      },
      drugdatahandlePageChange(page) {
        this.drugdatacurrentPage = page;
        this.drugdataItems(this.type);
      },
      drugdatahandlePageSizeChange(pageSize) {
        this.drugdatapageSize = pageSize;
        this.drugdataItems(this.type);
      },
      drugdataItems(type) {
        const data = {
          currentPage: this.drugdatacurrentPage,
          pageSize: this.drugdatapageSize,
          type:type ? type : this.type,
        };
        this.$api.drugdata.allDrugdata(data).then((res) => {
          this.drugdatacurrentPage = res.data.pagination.currentPage;
          this.drugdatapageSize = res.data.pagination.pageSize;
          this.drugdatatotal = res.data.pagination.total;
          if(type === 1){
            this.zhongxichengyao = res.data.data;
          }else{
            this.zhongyao = res.data.data;
          }
          
        });
      },
      operateWarehousing(){
        if(this.Changedrugdata.length === 0){
          return this.$message({
              message: "你没有勾选需要入库的物品",
              type: "warning",
          });
        }
        this.$api.goods.operateWarehousing(this.Changedrugdata).then((res) => {
          if(res.data.code === 200){
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.loadItems()
          }
        });
      },
      loadItems() {
        const data = {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
        };
        this.$api.goods.allWarehousingdrugdata(data).then((res) => {
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        });
      },
      formatTime(row,column){
          let data = row[column.property]
          let dtime = new Date(data)
          const year = dtime.getFullYear()
          let month = dtime.getMonth() + 1
          if (month < 10) {
              month = '0' + month
          }
          let day = dtime.getDate()
          if (day < 10) {
              day = '0' + day
          }
          let hour = dtime.getHours()
          if (hour < 10) {
              hour = '0' + hour
          }
          let minute = dtime.getMinutes()
          if (minute < 10) {
              minute = '0' + minute
          }
          let second = dtime.getSeconds()
          if (second < 10) {
              second = '0' + second
          }
          return year+ '-' + month+ '-' + day
      }
    },
    created() {
      this.loadItems();
      this.drugdataItems(this.type);
      this.getallsupplier()
    },
    computed: {
      changedrugdatatotalPrice() {
        let total = 0
        this.Changedrugdata.forEach(i => {
          total += i.packageprice * i.procurenum;
        });
        return total.toFixed(2);
      },
      drugpieceprice(){
        let pieceprice = 0
        if (this.drugdataForm.dismounting === 1 && this.drugdataForm.pieceNum && this.drugdataForm.sellprice ) {
          console.log(this.drugdataForm.pieceNum)
          console.log(this.drugdataForm.sellprice)
          pieceprice = this.drugdataForm.sellprice / this.drugdataForm.pieceNum
        }else{
          pieceprice = 0
        }
        this.drugdataForm.pieceprice = pieceprice.toFixed(2)
        console.log(this.drugdataForm.pieceprice)
        return pieceprice.toFixed(2);
      },
    }
  };
  </script>
  <style>
  .mr30{
    margin-right: 30px;
  }
  .colorff2222{
    color: #ff2222;
  }
  .justifybetween{
    justify-content: space-between;
  }
  .aligncenter{
    align-items: center;
  }
  .ExpirationDate .el-input__inner{
    width: 70%;
  }
  .GenerationDate .el-input__inner{
    width: 70%;
  }
  .searchdrugdata .pagination{
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .searchdrugdata .el-tabs__content {
    overflow: auto;
    position: relative;
  }
  .mb5{
    margin-bottom: 5px;
  }
  .colorgrad{
    color: #cccccc;
  }
  .searchdrugdata .datalistbax{
    min-height: 588px;
    max-height: 588px; 
  }
  .searchdrugdata .el-tabs__header {
    padding: 0;
    margin: 0;
  }
  .ml30{
    margin-left: 30px;
  }
  .coloradd{
    color: #409EFF;
  }
  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ml60{
    margin-left: 60px;
  }
  .flex_row_space-between_center{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .pd20{
    padding: 20px;
  }
  .searchdrugdata .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
    border-bottom: none;
    border-radius: 0;
  }
  .searchdrugdata .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(n+1) {
    padding-left: 20px;
    border-right: 1px solid #E4E7ED;
    border-left: none;
  }
  .searchdrugdata .el-card__body {
    padding: 0!important;
  }
  .drugdata .el-table__body-wrapper{
    min-height: 550px;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 250px;
  }
  .w250{
    width: 250px;
  }
  .w120{
    width: 120px;
  }
  .flex{
    display: flex;
  }
  .justifyend{
    justify-content: end;
  }
  button:focus {
      outline: none;
  }
  .text {
    font-size: 14px;
  }
  
  .item {
    margin-bottom: 18px;
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  
  .box-card {
    width: 100%;
  }
  .w50{
    width:50%;
  }
  .goodsForm{
    display: flex;
    flex-wrap: wrap;
  }
  .goodsForm .el-form-item{
    width:30%;
  }
  .ishover:hover{
    background-color: #f5f5f5;
  }
  .w105{
    width: 105px;
  }
  .alignright{
    text-align: right;
  }
  .pd5{
    padding: 5px;
  }
  .pd10{
    padding: 10px;
  }
  </style>
  